/* DROPDOWN BAR START */
.left-side-menu-bar .lb-menu-list {
    opacity: 0;
    display: none;
    transition: 0.8s;
}
.left-side-menu-bar .lb-menu-list.showDropdown {
    opacity: 1;
    display: block;
    transition: 0.8s;
}

/* DROPDOWN BAR ENDS */

.head-with-searchbar {
    display: flex;
    justify-content: space-between;
    padding: 0 20px 10px 20px;
    align-items: center;
}

.db-signoutBtn {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}
button.signOut {
    background: transparent;
    color: #000;
    outline: none;
    border: none;
    margin: 0;
}

.signout-btn {
    text-align: center;
}

label.checkbox-container {
    display: flex;
    align-items: center;
}

.lb-head-icon {
    width: 20px;
    height: 20px;
    border-radius: 50%;
    border: 1px solid #b8b6b6;
    display: flex;
    justify-content: center;
    align-items: center;
}

.lb-head-icon i {
    color: #858080;
    font-size: 14px;
}

label.checkbox-container a.pm-link {
    margin-top: 0;
}

.top-bar.adminTopBar {
    display: flex;
    justify-content: right;
    align-items: center;
    flex-wrap: wrap;
    padding-right: 20px;
}

.top-bar.adminTopBar .img_wrapper .img {
    height: 60px;
    width: 60px;
}
.lb-board-heading {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 20px;
    padding-right: 20px;
}
.top-bar.adminTopBar .img_wrapper .img img {
    width: 60px;
    height: 60px;
    object-fit: cover;
    border-radius: 50%;
}

.top-bar.adminTopBar .img_wrapper {
    padding: 0;
}

h2.integrate-head {
    margin-top: 25px;
}

/* CRM PAGE STYLE */
.crm-integration p.crm-para {
    max-width: 500px;
}

.integrations-tab {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
}

.checkbox-container input[type="checkbox"] {
    flex: 0 0 5%;
}

.innerMulti-tabs {
    background-color: #eeefef;
    border: 1px solid #b8b6b6;
    flex: 0 0 33.33%;
    max-width: 32%;
}

.tab-cards {
    padding: 20px;
    background-color: #fff;
}

.tab-cardHeading h3 {
    margin: 0;
}

.tab-cardPara p {
    font-size: 15px;
    letter-spacing: 0px;
    color: #959394;
    font-weight: 400;
    margin-top: 15px;
}

button.dropbtn,
.dropdown-content button {
    border: none;
    background: #fff;
    color: green;
    padding: 6px;
    font-size: 12px;
    border: 1px solid #b8b6b6;
    border-radius: 0;
    margin: 0;
    width: 140px;
    font-weight: 400;
}

.tab-cardButtons {
    text-align: right;
    background-color: #eeefef;
    padding: 15px;
}

.tab-cards-head {
    display: flex;
    align-items: center;
    gap: 20px;
    flex-wrap: wrap;
}

.tab-cardIcon {
    height: 40px;
}

button.dropbtn.notconnected {
    color: red;
}


/* CALENDAR MEETING CSS */
.upperboardInner {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
}

.uprBoardLeft {
    flex: 0 0 27%;
    max-width: 26%;
}

.uprBoardRyt {
    flex: 0 0 73%;
    max-width: 72%;
}

.uprBoardLeft {
    border-radius: 28px;
    filter: drop-shadow(0 0 4.5px rgba(5, 5, 5, 0.30));
    background-color: #fefefe;
    border: 1px solid #b8b6b6;
    padding: 20px;
}

.th-head h6 {
    font-size: 21px;
    color: #000;
    font-weight: bold;
    font-family: "Myriad Pro";
    margin: 7px 0;
}

.tn-number p {
    font-size: 42px;
    color: #4378bc;
    font-weight: bold;
    font-family: "Myriad Pro";
    margin: 18px 0;
}

.wrapper-main {
    display: flex;
    flex-direction: column;
}

.upperBoard {
    flex: 0 0 40%;
}

.downBoard {
    flex: 0 0 60%;
}

.barChart canvas {
    max-width: 380px;
    max-height: 111px;
}

.mr-heading {
    margin-bottom: 25px;
}

.meetingReports {
    border-radius: 28px;
    filter: drop-shadow(0 0 4.5px rgba(5, 5, 5, 0.30));
    background-color: #fefefe;
    border: 1px solid #b8b6b6;
    padding: 20px;
}

.reportsSec {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-bottom: 20px;
    flex-wrap: wrap;
}

.mrReportdiv {
    display: flex;
    margin: 10px;
    justify-content: space-between;
}

.barChart {
    flex: 0 0 47%;
    max-width: 47%;
}

.ar-heading p {
    margin: 0;
}

.circleChart {
    flex: 0 0 20%;
    max-width: 20%;
    height: 130px;
}

.mr-reports {
    flex: 0 0 25%;
    max-width: 25%;
}

.appointments-report {
    margin-top: 10px;
    padding: 20px;
}
.appointments-report {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
}
 select.customDate{
    border-radius: 0;
    width: max-content;
    max-width: 125px;
    margin: 0;
    margin-left: 5px;
}



/* TABLE STYLE */
.table-container {
    border-radius: 28px;
    filter: drop-shadow(0 0 4.5px rgba(5, 5, 5, 0.30));
    background-color: #fefefe;
    border: 1px solid #b8b6b6;
    overflow-x: auto;
    margin-top: 20px;
}

.table-container h3 {
    padding: 12px;
    font-weight: bold;
}

table {
    width: 100%;
    border-collapse: collapse;
    min-width: 800px;
}

th,
td {
    padding: 10px;
    border-bottom: 1px solid #ddd;
    text-align: center;
}

th {
    background-color: #f0eff0;
    font-weight: bold;
}

tr.grey-row {
    background: #f0eff0;
}

.long-text {
    max-height: 3em; 
    overflow: hidden;
    text-overflow: ellipsis; 
    word-wrap: break-word;
    white-space: pre-line; 
    max-width: 200px;
  }
input.tableSearch {
    max-width: 70px;
    padding: 3px;
    border: 1px solid #000;
    border-radius: 0;
    font-size:10px;
}
.table-header {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
  }
  
  .header-text {
        width: 100%;
    flex: 0 0 80%;
    margin: 0;
}
  
  
  .dropdwn-arw {
    cursor: pointer;
    flex:  0 0 10%;
  }
  
  .search-box {
    flex: 0 0 100%;

  }
  thead{height: 75px;}
/* RESPONSIVE */

@media(max-width:1024px){
    .top-bar.menu-open .mobile_sec span.openSpan:nth-of-type(2) {
        opacity: 0;
    }
   
    .top-bar.menu-open .mobile_sec span.openSpan:nth-of-type(1) {
        transform: rotate(45deg);
        position: relative;
        top: 6px;
    }

    .top-bar.menu-open .mobile_sec span.openSpan:nth-of-type(3) {
        transform: rotate(-45deg);
        top: -10px;
        position: relative;
    }

}

@media(max-width:969px) {
    .innerMulti-tabs {
        flex: 0 0 50%;
        max-width: 48.5%;
        margin-top: 20px;
    }
    .uprBoardLeft, .uprBoardRyt{flex: 0 0 100%;max-width: 100%;margin-top: 20px;}
    .upperboardInner{flex-direction: column-reverse ;}
    .mr-reports, .barChart, .circleChart{
        flex: 0 0 100%;max-width: 100%;margin-top: 20px;
    }
}

@media(max-width:639px) {
    .innerMulti-tabs {
        flex: 0 0 100%;
        max-width: 100%;
        margin-top: 20px;
    }
    .ar-heading, .sorting-detail {
        flex: 0 0 100%;
        text-align: center;
    }
    .sorting-detail{margin-top: 10px;}
    .sorting-btn{display: flex ;}
}

@media(max-width:400px){
    .appointments-report{padding: 20px 0;}
}