.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

/* CUSTOM CSS */

h2 {
  font-size: 29px;
  letter-spacing: -1px;
  line-height: 33px;
  color: #4178bc;
  font-weight: 600;
  font-family: "Poppins";
  filter: drop-shadow(0 0 2.5px rgba(187, 187, 187, 0.21));
}

h3 {
  font-size: 21px;
}

* {
  box-sizing: border-box;
}

input {
  padding: 11px 15px;
  margin: 8px 0;
  max-width: 100%;
  outline: none;
  border-radius: 16px;
  background-color: #fefefe;
  border: 1px solid #bebebf;
}

input::placeholder {
  color: #b9baba;
}

a {
  text-decoration: none;
}

a.white {
  color: #fff;
}

a.blue {
  color: #4378bc;
}

button:active {
  transform: scale(0.90);
}

label {
  font-size: 17px;
  color: #171717;
  font-weight: 400;
  font-family: "Poppins";
  margin-top: 15px;
}

button {
  border-radius: 16px;
  font-family: "Poppins";
  border: 1px solid #4378bc;
  background-color: #4378bc;
  color: #ffffff;
  font-size: 12px;
  font-weight: bold;
  padding: 12px 45px;
  letter-spacing: 1px;
  text-transform: uppercase;
  transition: transform 80ms ease-in;
  outline: none;
  cursor: pointer;
  margin: 15px 5px;
}

.primary-btn {
  border-radius: 16px;
  border: 1px solid #4378bc;
  background-color: #4378bc;
  color: #ffffff;
  font-size: 15px;
  font-weight: 600;
  padding: 12px 30px;
  letter-spacing: 1px;
  text-transform: uppercase;
  transition: transform 80ms ease-in;
  outline: none;
  cursor: pointer;
  font-family: "Poppins";
}

button.primary-btn.secondary {
  background-color: transparent;
  color: #4378bc;

}

button.primary-btn.secondary a {
  color: #4378bc;
  background-color: transparent;
  font-family: "Poppins";
}

button.primary-btn:active {
  transform: scale(0.90);
}

.error-message {
  color: red;
  padding: 0;
  margin: -15px 20px 8px 18px;
  font-size: 12px;
}

hr {
  margin: 0;
}




/* --------------------------------- */


.form-group {
  width: 361px;
  border-radius: 16px;
  background-color: #fefefe;
  border: 1px solid #bebebf;
  margin-bottom: 20px;
}

input#ec-mobile-number {
  padding: 10px;
  margin: 0;
  border-bottom-left-radius: 0;
  border-top-left-radius: 0;
  border-left: 1px solid #bebebf;
}

.form-group:focus {
  color: #212529;
  background-color: #fff;
  border-color: #86b7fe;
  outline: 0;
  box-shadow: 0 0 0 0.25rem rgb(13 110 253 / 25%);
}

.form-group input {
  display: inline-block;
  width: auto;
  border: none;
}

.form-group input:focus {
  box-shadow: none;
}

input[type=radio] {
  flex: 0 0 8%;
}

h5 {
  font-size: 21px;
  font-family: "Poppins";
}

.tertiary {
  border-radius: 12px;
  background-color: #fefefe;
  border: 1px solid #060505;
  font-size: 15px;
  color: #171717;
  font-weight: 300;
  font-family: "Poppins";padding: 8px 10px;
}

h6{
  font-size: 19px;
color: #050505;
font-weight: 400;
font-family: "Poppins";
}