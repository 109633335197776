.top-bar {
    width: 100%;
    height: 80px;
    border-bottom-left-radius: 32px;
    border-bottom-right-radius: 32px;
    background-color: #4378bc;
}

.profile-top-bar {
    display: flex;
    justify-content: right;
    padding-right: 40px;
    align-items: center;
}

.profile-top-bar a {
    font-size: 35px;
    color: #fff;
}

.profile-top-bar a:active {
    color: #000;
}

.main-board {
    display: flex;
    padding: 20px;
}

.left-board {
    padding: 20px;
    max-width: 25%;
    min-height: 831px;
    background-color: #fefefe;
    border-right: 1px solid #bebebf !important;
    flex: 0 0 25%;
}

.right-board {
    padding: 20px;
    width: 100%;
    min-height: 831px;
    background-color: #fefefe;
    border-right: 1px solid #bebebf !important;
    flex: 0 0 75%;
}

h1.right_heading {
    margin: 0;
}

button.pd-btn {
    font-weight: 300;
    font-family: "Helvetica Neue";
    letter-spacing: 0px;
    background: none;
    border: none;
    color: #000;
    margin-top: -10px;
    width: 100%;
    text-transform: none;
    text-align: left;
    padding: 0;
}

button.pd-btn.active-btn a.pm-link {
    font-size: 19px;
    max-width: 300px;
    height: 42px;
    border-radius: 18px;
    background-color: #f1f1f1;
    border: 1px solid #bebebf;
}

a.pm-link {
    display: flex;
    align-items: center;
    padding: 15px;
    gap: 10px;
    justify-content: flex-start;
    font-weight: 300;
    font-family: "Helvetica Neue";
    width: 254px;
    height: 42px;
    font-size: 19px;
    letter-spacing: 0px;
    background: none;
    border: none;
    color: #000;
    margin: 0;
    text-transform: none;
    margin-top: 20px;
}

a.pm-link:hover {
    border-radius: 18px;
    filter: drop-shadow(0 0 2.5px rgba(21,21,21,0.26));
    background-color: #f4f4f4;
    border: 1px solid #bebebf;
}
a.pm-link i {
    flex: 0 0 20%;
    text-align: center;
}

a.pm-link img {
    flex: 0 0 20%;
    text-align: center;
    max-width: 20%;
    max-height: 19px;
    width: 20px;
    object-fit: contain;
}

.upper_right {
    margin-bottom: 20px;
}

.right_heading {
    font-size: 29px;
    color: #050505;
    font-weight: bold;
    font-family: "Roboto";
}

.save,
.Deactivate {
    background: none;
    border: none;
    font-size: 21px;
    letter-spacing: 0px;
    color: #4178bc;
    font-weight: 300;
    font-family: "Helvetica Neue";
}
.pm-span {
    flex: 0 0 80%;
    max-width: 80%;
}

.img_wrapper {
    padding: 20px;
}

.img {
    width: 90px;
    height: 90px;
    border-radius: 45px;
    background-color: #fefefe;
    border: 1px solid #bebebf;
    overflow: hidden;
}

.form-sec {
    margin-top: 5px;
    display: flex;
    flex-direction: column;
    flex: 0 0 50%;
    max-width: 50%;
}

form.profile-up {
    padding: 20px;
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    text-align: left;
}

form input,
select {
    padding: 10px;
    width: 361px;
    border-radius: 8px;
    background-color: #fefefe;
    border: 1px solid #bebebf;
    margin-bottom: 20px;
}
input#otp {
    margin-left: 10px;
}
input.pu-input.nonEditable,
select.pu-input.nonEditable {
    cursor: not-allowed;
    border: none;
    border-bottom: 1px solid #bebebf;
    border-radius: inherit;
    max-width: 300px;
}

input.cp-input {
    margin: 8px 0;
}

.hidden {
    display: none;
}


/* UPDATE PASSWORD POPUP */
/* ProfileDashboard.css */

.error {
    background-color: #ffcccc;
    color: #cc0000;
    padding: 10px;
    border-radius: 5px;
    margin-bottom: 15px;
    text-align: center;
}

.success {
    background-color: #ccffcc;
    color: #009900;
    padding: 10px;
    border-radius: 5px;
    margin-bottom: 15px;
    text-align: center;
}

/* Error popup */
.error-popup {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: #ffcccc;
    color: #cc0000;
    padding: 20px;
    border-radius: 5px;
    z-index: 1000;
    border: 1px solid #cc0000;
    display: none;
}

.error-popup.show {
    display: block;
}

.success-popup {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
    padding: 20px;
    border-radius: 5px;
    z-index: 1000;
    display: none;
}

.success-popup.show {
    display: block;
}


/* SIDEBAR STYLE */
ul.lb-menu-list {
    list-style: none;
    padding: 0;
    margin: 0;
}


/* TWO FA SECURITY PAGE STYLE */
.twoFaCheckButton, .smsFaCheckButton{
    background-color: #d2d2d2;
    width: 90px;
    height: 45px;
    border-radius: 90px;
    cursor: pointer;
    position: relative;
    transition: 0.3s;
    margin: 0
}
.twoFaCheckButton::before, .smsFaCheckButton::before{
    position: absolute;
    content: '';
    background-color: #fff;
    width:40px;
    height: 40px;
    border-radius: 100px;
    margin: 2px;
    transition: 0.3s;
}
input#twoFaCheck:checked + .twoFaCheckButton, input#smsFaCheck:checked + .smsFaCheckButton{
    background-color: #4178bc;
}

input#twoFaCheck:checked + .twoFaCheckButton::before, input#smsFaCheck:checked + .smsFaCheckButton::before{
    transform: translateX(45px);
}
input#twoFaCheck, input#smsFaCheck{display: none;}

.two-fa-heading {
    display: flex;
    justify-content: space-between;
    padding: 0 20px 0 0;
}

.two-fa-head {
    max-width: 500px;
}
.showToggle h4 {
    margin-top: 20px;
}
.codeConfirmButton, .smscodeConfirmButton {
    text-align: right;
    padding-right: 30px;
}

/* SMS */
.sms-fa {
    margin-top: 35px;
}
.sms-fa h5 {
    margin: 15px 0;
}

/* RESPONSIVE */
@media (max-width:1024px) {
    .left-board {
        flex: 0;
        max-width: 0;
    }

    .right-board {
        flex: 0 0 100%;
        max-width: 100%;
    }

    .mobile_sec {
        position: absolute;
        left: 20px;
        top: 28px;
        width: 35px;
        height: 25px;
        background: transparent;
        line-height: 0;
    }

    .mobile_sec span {
        display: inline-block;
        width: 100%;
        height: 4px;
        background: #fff;
        margin: 2px 0;
        transition: 0.3s;
    }

    .left-board {
        width: max-content;
        padding: 0
    }

    .left-board-inner {
        position: absolute;
        left: 0;
        top: 80px;
        width: 100%;
        background: #fff;
        padding: 0 20px;
        text-align: left;
        transition: 0.3s;
        max-height: 0;
        opacity: 0;
        visibility: hidden;
    }

    .left-board.open .left-board-inner {
        max-height: 9999px;
        opacity: 1;
        visibility: visible;
        z-index: 999;
        padding-top: 20px;
    }

    .open span.openSpan:nth-of-type(2) {
        opacity: 0;
    }

    .open span.openSpan:nth-of-type(1) {
        transform: rotate(45deg);
        position: relative;
        top: 6px;
    }

    .open span.openSpan:nth-of-type(3) {
        transform: rotate(-45deg);
        top: -10px;
        position: relative;
    }

    .save,
    .Deactivate {
        font-size: 16px;
    }

    h1.right_heading {
        font-size: 18px;
    }
}


@media(max-width:869px) {
    .form-sec {
        flex: 0 0 100%;
        max-width: 100%;
    }
}