.calendar-head {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 25px;
}

.event-form {
  padding: 20px;
  margin-top: 20px;
}

/* CALENDAR */
.react-calendar__navigation button {
  background: transparent;
  border: none;
  outline: none;
  color: #000;
  padding: 10px;
  font-size: 15px;
}

.react-calendar__year-view__months button,
.react-calendar__decade-view__years button {
  background: transparent;
  border: 1px solid grey;
  color: #000;
  outline: none;
}

.react-calendar__year-view__months,
.react-calendar__decade-view__years {
  justify-content: center;
}

.react-calendar__month-view__days button {
  color: #aba8a6;
  background: transparent;
  outline: none;
  border: none;
  padding: 15px 0;
}


.react-calendar__month-view__weekdays div {
  text-align: center;
}

.react-calendar__month-view__days button {
  margin: 0;
}

.calendarContainer {
  border-radius: 28px;
  filter: drop-shadow(0 0 4.5px rgba(5, 5, 5, 0));
  background-color: #fefefe;
  border: 1px solid #b8b6b6;
  overflow: hidden;
}

.calendarTopBar {
  background: #4378bc;
  height: 35px;
  
}

.calendarContainerinr {
  display: flex;
  justify-content: space-between;
}

.cm-side {
  flex: 0 0 50%;
  max-width: 50%;
  border-right: 1px solid #b8b6b6;
  padding: 20px;
}

.cc-side {
  flex: 0 0 50%;
  max-width: 50%;
}

abbr[data-bs-original-title],
abbr[title] {
  text-decoration: none !important;
}

button.react-calendar__navigation__label {
  font-weight: 500;
  font-size: 15px;
}

.react-calendar__navigation {
  text-align: center;
}

.event-form form label {
  margin: 0;
}

p.text-right {
  padding: 20px 0 0 20px;
  font-weight: bold;
  font-size: 22px
}

.calendarPg {
  padding-bottom: 20px;
}

/* CALENDAR EVENTS DETAILS START */
.ed-popup {
  margin-top: 20px;
  align-items: center;
}

.eventDetail-close-btn {
  text-align: right;
  margin: 20px 0;
  font-size: 35px;
  cursor: pointer;
}

.ed-popup-content h3 {
  font-size: 30px;
  text-align: center;
  margin-bottom: 50px;
}

.event-item {
  display: flex;
  align-items: flex-start;
  gap: 10px;
  margin: 20px 0 0;
  flex-direction: column;
}

.event-item p {
  margin: 0;
}

.ed-popup-content {
  max-width: 600px;
  padding: 20px 40px;
  margin: 0 auto;
  border: 1px solid #989595;
  border-radius: 10px;
  overflow: auto;
}

.event-item h6 {
  margin: 0;
  font-weight: bold;
  font-size: 17px;
  flex: 0 0 40%;
}

/* CALENDAR EVENTS DETAIL ENDS */







/* EVENTS */
.calendar-icon a {
  color: #000;
  font-size: 20px;
}

textarea#description {
  padding: 10px;
  max-width: 361px;
  border-radius: 16px;
  background-color: #fefefe;
  border: 1px solid #bebebf;
  margin-bottom: 20px;
}

.calendar-top-bar {
  display: flex;
  justify-content: right;
  padding-right: 40px;
  align-items: center;
  filter: drop-shadow(0 0 4.5px rgba(5,5,5,0.17));
background-color: #fefefe;
border: 1px solid #b8b6b6;
}

.calendar-top-bar a {
  font-size: 35px;
  color: #000;
}

textarea#description {
  padding: 10px;
  width: 361px;
  border-radius: 16px;
  background-color: #fefefe;
  border: 1px solid #bebebf;
  margin: 20px 0
}


/* SELECT */
.custom-select {
  position: relative;
  width: 200px;
}

.custom-select select {
  display: block;
  width: 100%;
  padding: 10px;
  font-size: 16px;
  border-radius: 16px;
  background-color: #fefefe;
  border: 1px solid #bebebf;
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  margin: 10px 0 0;
}

.custom-select::after {
  content: '\3C';
  position: absolute;
  top: 50%;
  right: 10px;
  pointer-events: none;
  color: #555;
  transform: translateY(-50%) rotate(-90deg);
  font-size: 18px;
}

.location-inner {
  margin-top: 10px;
  display: flex;
  justify-content: space-between;
  max-width: 320px;
  flex-wrap: wrap;
}

.location-inner div {
  border: 1px solid #bebebf;
  padding: 15px 10px;
  width: 100px;
  border-radius: 16px;
  display: flex;
  flex-direction: column;
  text-align: center;
  justify-content: center;
  margin-top: 15px;
}

.location-inner div p {
  margin: 0;
  margin-top: 8px;
}


i.fa-solid.fa-users {
  color: #ef445c;
}

i.fa-solid.fa-user-plus {
  color: #4378bc;
}



/* MEETING SETTING */
input.check {
  margin-right: 10px;
}

.checkbox-text {
  max-width: 60px;
  text-align: center;
  padding: 8px 15px;
}

.checkbox-select {
  margin: 8px 0 8px 8px;
  max-width: 100%;
  outline: none;
  border-radius: 16px;
  background-color: #fefefe;
  border: 1px solid #bebebf;
  padding: 8px 15px;
}

.available_hr {
  max-width: 300px;
  margin: 50px 0;
}

.available_hr p {
  color: #8f8e8e;
}
.cf-innerContent {
  margin-top: 28px;
}


h4.cf_innerhead {
  font-size: 18px;
}

.cf-innerContent {
  padding: 0 60px 0 20px;
}

.inrFlex {
  flex: 0 0 70%;
}

p.cf-inrRight {
  flex: 0 0 25%;
  text-align: left;
}

.cf-inrHead {
  display: flex;
  justify-content: space-between;
  width: 100% !important;
}

p.cf-content {
  max-width: 340px;
  color: #7b7c7c;
}

p.cf-inrRight {
  color: #416cb4;
}

.calender-btns {
  margin-top: 50px;
  text-align: right;
}





/*  Additional Info */
.questions-block {
  padding: 20px
}

.main-question {
  max-width: 500px;
  border: 1px solid #bbb8b8;
  border-radius: 20px;
}

.questions {
  padding: 20px 30px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.questions-block {
  padding: 20px;
}

.ques-icon {
  display: flex;
  gap: 20px;
  align-items: start;
  margin-right: 18px;
}

.ques-icon img {
  width: 22px;
  height: 20px;
  object-fit: contain;
  margin-top: 5px;
}

.ques1,
.ques2 {
  border-bottom: 1px solid #bbb8b8;
}

.ques4 {
  border-top: 1px solid #bbb8b8;
}

.add-que-btn a {
  color: #4378bc;
}

.add-que-btn {
  margin-top: 30px;
}


/* COMMUNICATION */

.ques-text p {
  font-size: 20px;
  margin: 0;
}

.ques-text span {
  margin-top: 5px;
  color: #7b7c7c;
}

.ques-menu {
  display: flex;
  gap: 40px;
  align-items: center;
}

.on-of-btn p {
  background: #b4b0b0;
  padding: 4px 10px;
  border-radius: 10px;
}

.advance-notify {
  padding: 20px;
}

.ques-menu {
  display: flex;
  gap: 40px;
  align-items: center;
}

.on-of-btn p {
  background: #b4b0b0;
  padding: 4px 10px;
  border-radius: 10px;
}

.notigy-block-inr {
  max-width: 500px;
  border: 1px solid #b4b0b0;
  border-radius: 16px;
  margin: 40px 0;
  padding: 20px;
  text-align: center;
}

.notify-inner {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 20px;
}

.notify-block-text p {
  text-align: left;
  line-height: 1.5;
  margin: 0;
}

/* AVAILABILITY */
.avail-schedule {
  padding: 20px;
}

.scheduleVsView {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
}

.listVsCalendar {
  display: flex;
  padding: 8px 22px;
  border-radius: 10px;
  background-color: #ecf4fb;
  border: 1px solid #bebebf;
  align-items: center;
  gap: 18px;
  height: max-content;
}

span.icon-name {
  margin-left: 8px;
}

.calendarView,
.listView {
  border-radius: 10px;
  border: 1px solid transparent;
  padding: 5px 8px;
}
.listView:hover, .calendarView:hover {
  background: #fefefe;
  border: 1px solid #a3a4a4;
}

span.scheduleSpan {
  font-size: 21px;
  letter-spacing: 0px;
  color: #4178bc;
  font-weight: 300;
  font-family: "Poppins";
}

p.calendarPara {
  font-size: 16px;
  color: #7d7d7e;
  font-weight: 300;
  font-family: "Poppins";
}

.availabilty-main-div {
  border-radius: 10px;
  filter: drop-shadow(0 0 4.5px rgba(5, 5, 5, 0.09));
  background-color: #fefefe;
  border: 1px solid #b8b6b6;
}

.ac-head {
  display: flex;
  gap: 25px;
}

.ac-head {
  display: flex;
  gap: 25px;
  padding: 20px;
}

.lv-availability-calendar {
  border-top: 1px solid #b8b6b6;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

.calendar-main,
.calendar-data {
  flex: 0 0 50%;
  max-width: 50%;
  padding: 20px;
}

.calendar-main {
  border-right: 1px solid #b8b6b6;
}

.availListLi {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  padding: 23px 2px;
  border-bottom: 2px solid #9e9e9f;
  position: relative;
  flex: 0 0 80%;
  max-width: 100%;
}
li.availList-li {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.liIconClone {
  flex: 0 0 18%;
  max-width: 18%;
  text-align: center;
}

.week-day,
.edit-ur-weekday {
  flex: 0 0 20%;
  max-width: 20%;
}

.dayAvailability {
  flex: 0 0 55%;
  max-width: 55%;
}
.edit-ur-weekday {
  opacity: 0;
  text-align: center;
}
span.closePopupIcon {flex: 1 0 0;color: #aeadad;text-align: right;font-size: 22px;}

.switch-container {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    gap: 10px;
}
span.switch-status {
  font-size: 19px;
  color: #050505;
  font-weight: 400;
  font-family: "Poppins";
}
.switch-containerDiv {
  font-size: 16px;
  color: #050505;
  font-weight: 400;
  margin: 15px 0 10px 15px;
}
.availListLi:hover .edit-ur-weekday {
  opacity: 1;
}
input.tertiary.tertiaryInput {
  max-width: 100px;
  border-radius: 0;
  margin: 0 15px;
}

.avail-editMode {
  position: absolute;
  padding: 20px;
  top: 30%;
  z-index: 99999;
  left:8%;
  border-radius: 10px;
  filter: drop-shadow(0 0 4.5px rgba(5, 5, 5, 0.09));
  background-color: #fefefe;
  border: 1px solid #b8b6b6;
}

.saveBtn{text-align: right;margin-top: 15px;}
.saveBtn button {margin: 0;}

/* CALENDAR */
.cv-availability-calendar .react-calendar__month-view__weekdays {
  background-color: #ecf4fb;
  border: 0.44px solid #bab6b6;
  padding: 10px 0;
  color: #4378bc;
}

.cv-availability-calendar .react-calendar__month-view__days button {
  padding: 50px;
  background-color: #fefefe;
  border: 1px solid #b8b6b6;
  border-radius: 0;
  color: #4378bc;
  text-align: left;
}
.notigy-block-inr p{margin: 0;}
.questions-block h3{margin-bottom: 40px;margin-top: 25px;}
/* RESPONSIVE */
@media(max-width:939px) {
  .cc-side {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .cm-side {
    display: none;
    opacity: 0;
  }
}

@media(max-width:500px) {
  textarea#description {
    max-width: 260px;
  }

  .event-form {
    text-align: center;
  }

  label.meeting-label {
    justify-content: center;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .calender-flex div {
    flex: 0 0 100%;
    margin-top: 20px;
  }

  .cf-left {
    border: none;
  }

  p.cf-content {
    text-align: left;
  }

  p.cf-inrRight {
    display: none
  }

  .ques-menu {
    display: none;
  }

  .custom-select {
    width: 100%;
  }
  .main-question {
    margin-top: 15px;
  }

  .notigy-block-inr p {
    margin: 0;
  }
  .calendar-head{padding: 0;}
}

/* CALENDAR EVENT SETTING */
button.event-date {
  background: #e1e7ef;
  color: #4378bc;
}
.event-item div {
  display: flex;
    max-width: 100%;
    width: 100%;
    margin-bottom: 10px;
    flex: 0 0 100%;
    flex-wrap: wrap;
}
.pagination{margin-top: 20px;justify-content: center;}
span.paginationSpn {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 20px;
}