.body {
    align-items: center;
    display: flex;
    justify-content: center;
    flex-direction: column;
    font-family: "Montserrat";
    min-height: 100%;
    margin: 10%;
}

.body.loginBody {
    position: relative;
}

.container {
    position: relative;
    width: 850px;
    max-width: 100%;
    min-height: 480px;
    background-color: #fff;
    border-radius: 50px;
    overflow: hidden;
    box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22);
}

.sign-up,
.sign-in {
    position: absolute;
    top: 0;
    height: 100%;
    transition: all 0.6s ease-in-out;
    left: 0;
}

.sign-in {
    width: 50%;
    z-index: 2;
}

.sign-up {
    width: 50%;
    opacity: 0;
    z-index: 1;
}

form.registration-form {
    background-color: #ffffff;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding: 0 50px;
    height: 100%;
    text-align: center;
}

h1 {
    font-weight: bold;
    margin: 0;
    margin-bottom: 20px;
}

a.fp-navigate {
    text-decoration: underline;
}

a.fp-navigate:active {
    color: #4378bc;
}

input.registrationPage {
    width: 335px;
}

.forgot-password-btn {
    margin-top: 10px;
}

.create-acc-btn {
    display: none;
    opacity: 0;
}

#signIn,
#signUp {
    background-color: transparent;
    border: 1px solid #fff;
}

.container.right-panel-active .sign-in {
    transform: translateX(100%);
}

.container.right-panel-active .sign-up {
    transform: translateX(100%);
    opacity: 1;
    z-index: 5;
}

.overlay-container {
    position: absolute;
    top: 0;
    left: 50%;
    width: 50%;
    height: 100%;
    overflow: hidden;
    transition: transform 0.6s ease-in-out;
    z-index: 100;
}

.container.right-panel-active .overlay-container {
    transform: translateX(-100%);
}

.overlay {
    position: relative;
    color: #fff;
    background-color: #4378bc;
    left: -100%;
    height: 100%;
    width: 200%;
    background: linear-gradient(to right, #4378bc, #4378bc);
    transform: translateX(0);
    transition: transform 0.6s ease-in-out;
    margin: 0 auto;
}

.container.right-panel-active .overlay {
    transform: translateX(50%)
}

.overlay-left,
.overlay-right {
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding: 0 40px;
    text-align: center;
    top: 0;
    height: 100%;
    width: 50%;
    transform: translateX(0);
    transition: transform 0.6s ease-in-out;
}

.overlay-left {
    transform: translateX(-20%);
}

.overlay-right {
    right: 0;
    transform: translateX(0);
}

.container.right-panel-active .overlay-left {
    transform: translateX(0);
}

.container.right-panel-active .overlay-right {
    transform: translateX(20%);
}

.signup-error-message {
    color: red;
    font-size: 0.9rem;
    margin-top: 5px;
}

.signup-success {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: #ffffff;
    border: 1px solid #cccccc;
    padding: 20px;
    z-index: 1000;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    text-align: center;
}

.twofa-block {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 1000;
    background-color: #fff;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
}

.container.twofa-active {
    background: #000;
    opacity: 0;
    display: none;
}
.back-to-login {
    text-align: right;
    margin-bottom: 8px;
}

.back-to-login i {
    font-size: 20px;
}

/* RESPONSIVE */
@media(max-width:900px) {

    .overlay-right,
    .overlay {  
        display: none;
        opacity: 0;
    }

    .sign-in,
    .sign-up {
        width: 100%;
        opacity: 1000;
    }

    .create-acc-btn {
        display: block;
        opacity: 1;
    }

    .signToggle {
        background-color: transparent;
        color: #4378bc;
        border: none;
        outline: none;
        margin: 0;
        padding: 0
    }

    .overlay-container {
        opacity: 0;
        z-index: 0;
    }
}