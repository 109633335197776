.userForm{
    align-items: center;
    display: flex;
    flex-direction: column;
    font-family: Montserrat;
    justify-content: center;
    margin: 10%;
    min-height: 100%;
}
.userForm .container{
    background-color: #fdfefe;
    border: 1px solid #bebebf;
    border-radius: 46px;
    filter: drop-shadow(0 0 4.5px rgba(21, 21, 21, .84));
    height: 100%;
    max-width: 100%;
    padding: 50px 100px;
    width: 740px;
}