* {
    box-sizing: border-box;
}

.ps-body {
    align-items: center;
    display: flex;
    justify-content: center;
    flex-direction: column;
    font-family: "Montserrat";
    min-height: 100%;
    margin: 10%;
}


.fp-container {
    width: 740px;
    max-width: 100%;
    height: 100%;
    border-radius: 46px;
    filter: drop-shadow(0 0 4.5px rgba(21, 21, 21, 0.84));
    background-color: #fdfefe;
    border: 1px solid #bebebf;
    padding: 50px 100px;
}

.fp-start,
.Es-start {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.fp-heading {
    font-size: 42px;
    color: #050505;
    font-weight: bold;
    font-family: "Roboto";
    margin: 20px 0 0;
    text-align: center;
}

.fp-para p {
    font-size: 20px;
    color: #171717;
    font-weight: 300;
    font-family: Helvetica, sans-serif;margin-top: 10px;
}

.Es-start .fp-para p {
    text-align: center;
}
input.fp-input {
    width: 556px;
    margin-bottom: 20px;
    margin-top: 20px
}

.buttons {
    text-align: right;
    width: 100%;
}

span.color-change {
    color: #4378bc;
}



input.up-input {
    margin: 5px 0 35px;
}

.up-btn {
    text-align: center;
}

form.up-form {
    margin-top: 40px;
}


@media(max-width:767px){
    .fp-container{
        width: 600px;
    }
    .fp-heading{
        font-size: 35px;
    }
    .fp-para p{
        font-size: 18px;
    }
    input {
        width: 470px;
    }
    .primary-btn{
        font-size: 12px;
    }
}

@media(max-width:639px){
    .fp-container{
        width: 500px;
        padding: 50px;
    }
    .fp-heading{
        font-size: 30px;
    }
    .fp-para p{
        font-size: 14px;
    }
    input {
        width: 380px;
        margin-top: 13px;
    }
    .primary-btn{
        font-size: 12px;
    }
}

@media(max-width:650px){
    .fp-container{
        width: 420px;
        padding: 30px 20px;
    }
    .fp-heading{
        font-size: 30px;
    }
    .fp-para p{
        font-size: 14px;
        text-align: center;
    }
    input {
        width: 380px;
        margin-top: 13px;
    }
    .primary-btn{
        font-size: 12px;
    }
}
@media(max-width:500px){
    input.fp-input{max-width: 330px;}
}
@media(max-width:450px){
    .fp-container{
        width: 350px;
        padding: 20px;
    }
    .fp-heading{
        font-size: 25px;
    }
    .fp-para p{
        font-size: 12px;
    }
    input.fp-input {
        max-width: 280px;
        margin-top: 13px;
    }
    .primary-btn{
        font-size: 10px;
    }
}



/* SUCCESS POPUP DESIGN */

/* passwordSetting.css */

/* passwordSetting.css */
/* passwordSetting.css */

/* ... (Your existing styles) */

.success-popup {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: #ffffff;
    padding: 20px;
    border: 1px solid #cccccc;
    border-radius: 5px;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
    z-index: 1000;
  }
  
  .success-popup p {
    margin: 0;
    font-size: 16px;
    color: #333333;
  }
  